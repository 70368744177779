import axios from 'axios'

const URL_PRESTADOR = '/api-prestador'
const URL_AGENDA = '/api-agenda'
const URL_ASSOCIADO = '/api-associado/util'
const URL_API_BANCOS = 'https://guibranco.github.io/BancosBrasileiros'

const atribuirParametros = (filtro) => {
  let parametros = []
  for (const [key, value] of Object.entries(filtro)) {
    if (value || value === 0) parametros.push(`${key}=${value}`)
  }

  if (parametros.length <= 0) return ''
  return parametros.length === 1 ? `?${parametros}` : `?${parametros.join('&')}`
}

export default {
  listaStatusAtivacao() {
    const url = `${URL_PRESTADOR}/util/status`
    return axios.get(url)
  },

  listaStatusAuxilio() {
    const url = `${URL_ASSOCIADO}/Status-auxilio`
    return axios.get(url)
  },

  // TODO: remover mock abaixo
  listaStatusAgenda() {
    const url = `${URL_AGENDA}/Status-auxilio`
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ data: [
          { id: 1, descricao: 'STATUS MOCK 1'},
          { id: 2, descricao: 'STATUS MOCK 2'},
          { id: 3, descricao: 'STATUS MOCK 3'},
        ]});
      }, 200);
    });
  },

  ufs() {
    const url = `${URL_ASSOCIADO}/uf`
    return axios.get(url)
  },

  cidadesPorUf(ufId) {
    const url = `${URL_ASSOCIADO}/cidade/${ufId}`
    return axios.get(url)
  },

  conselhosClasse() {
    const url = `${URL_PRESTADOR}/util/conselho`
    return axios.get(url)
  },

  profissoes() {
    const url = `${URL_PRESTADOR}/util/profissoes`
    return axios.get(url)
  },

  ufs_venda() {
    const url = `${URL_PRESTADOR}/uf`
    return axios.get(url)
  },

  cidadesPorUf_venda(ufId) {
    const url = `${URL_PRESTADOR}/cidade/uf/${ufId}`
    return axios.get(url)
  },

  cidadePorId(cidadeId) {
    const url = `${URL_ASSOCIADO}/cidade/${cidadeId}/buscar`
    return axios.get(url)
  },

  paises() {
    const url = `${URL_ASSOCIADO}/pais`
    return axios.get(url)
  },

  instituicoesFinanceiras() {
    const url = `${URL_API_BANCOS}/data/bancos.json`
    return axios.get(url, {
      transformRequest: (data, headers) => {
        delete headers.common['Authorization']
        return data
      }
    })
  },

  tiposContaBancaria() {
    const url = `${URL_ASSOCIADO}/tipo/conta`
    return axios.get(url)
  },

  sexos() {
    const url = `${URL_AGENDA}/utils/sexo`
    return axios.get(url)
  },

  estadosCivis() {
    const url = `${URL_AGENDA}/utils/estado-civil`
    return axios.get(url)
  },

  coresPessoa() {
    const url = `${URL_AGENDA}/utils/cor-pessoa`
    return axios.get(url)
  },

  secoesProntuario() {
    const url = `${URL_AGENDA}/utils/secao-diagnostico`
    return axios.get(url)
  },

  cid(filtro) {
    const parametros = atribuirParametros(filtro)
    const url = `${URL_AGENDA}/utils/cid${parametros}`
    return axios.get(url)
  },

  partesCorpoPorSexo(sexoId) {
    const url = `${URL_AGENDA}/utils/corpo-humano/${sexoId}`
    return axios.get(url)
  },

  viasAdministracaoMedicamento() {
    const url = `${URL_AGENDA}/utils/via-administracao`
    return axios.get(url)
  },

  /**
   * @param auxilioId 1 = MATERNIDADE, 2 = FUNERAL
   */
  tiposDocumentosAuxilio(auxilioId, filtro) {
    const parametros = atribuirParametros(filtro)
    const url = `${URL_ASSOCIADO}/tipo-arquivo-auxilio/${auxilioId}${parametros}`;
    return axios.get(url);
  },

  removerArquivoAssociado(arquivoId) {
    const url = `/api-associado/arquivos/${arquivoId}`;
    return axios.delete(url);
  }
}
