<template>
  <v-col cols="12" :md="cols">
    <v-text-field
      outlined
      v-model="text"
      hide-details="auto"
      v-mask="maskFormat"
      :placeholder="placeholder"
      :rules="rules"
      :label="label"
      :readonly="readonly"
      :disabled="disabled"
    ></v-text-field>
  </v-col>
</template>

<script>
import globalMethods from '../../../mixins/globalMethods.vue'

export default {
  props: [
    'manterMascara',
    'label',
    'placeholder',
    'cols',
    'row',
    'name',
    'maskFormat',
    'rules',
    'valueField',
    'type',
    'readonly',
    'disabled'
  ],
  data() {
    return {
      text: this.valueField || ''
    }
  },
  mixins: [globalMethods],
  watch: {
    valueField: {
      immediate: true,
      handler(newText, oldText) {
        if ((newText === '' || newText) && newText !== oldText) {
          this.text = newText
        }
      }
    },
    text: {
      immediate: true,
      handler() {
        this.$emit('update:value', typeof this.text === 'string' ? this.onlyNumbers(this.text) : this.text)
      }
    }
  }
}
</script>

<style scoped>
.label {
  font-weight: 600;
  color: rgb(98, 98, 98);
  margin-bottom: 5px;
}
</style>
