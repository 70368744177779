import axios from  'axios';

const URL_ASSOCIADO = '/api-associado';

const atribuirParametros = (filtro) => {
  let parametros = [];
  for (const [key, value] of Object.entries(filtro)) {
    if(value || value === 0) parametros.push(`${key}=${value}`);
  }

  if(parametros.length <= 0) return '';
  return parametros.length === 1 ? `?${parametros}` : `?${parametros.join('&')}`;
}

export default {
  filtroAuxilios(paginacao, filtro) {
    const parametros = atribuirParametros({...filtro, ...paginacao});
    const url = `${URL_ASSOCIADO}/auxilio${parametros}`;
    return axios.get(url);
  },

  alterarStatus(statusId, body) {
    const url = `${URL_ASSOCIADO}/auxilio/${statusId}/atualizar/status`;
    return axios.put(url, body);
  },

  auxilioPorId(auxilioId) {
    const url = `${URL_ASSOCIADO}/auxilio/${auxilioId}/detalhar`;
    return axios.get(url);
  },

  dadosBancariosPorAuxilio(auxilioId) {
    const url = `${URL_ASSOCIADO}/auxilio/${auxilioId}/dado-Bancario`;
    return axios.get(url);
  },

  documentosPorAuxilio(auxilioId, filtro) {
    const parametros = atribuirParametros(filtro)
    const url = `${URL_ASSOCIADO}/arquivos/${auxilioId}/listar${parametros}`;
    return axios.get(url);
  },

  filtroAuxiliosFuneral(paginacao, filtro) {
    const parametros = atribuirParametros({...filtro, ...paginacao});
    const url = `${URL_ASSOCIADO}/auxilio/funeral${parametros}`;
    return axios.get(url);
  },

  auxilioFuneralPorId(auxilioId) {
    const url = `${URL_ASSOCIADO}/auxilio/${auxilioId}/detalhar-funeral`;
    return axios.get(url);
  },

  documentosPorAuxilioFuneral(auxilioId, filtro) {
    const parametros = atribuirParametros(filtro);
    const url = `${URL_ASSOCIADO}/arquivos/${auxilioId}/listar${parametros}`;
    return axios.get(url);
  },

  cadastrarDocumentos(auxilioId, body) {
    const url = `${URL_ASSOCIADO}/arquivos/${auxilioId}/cadastrar-arquivo`
    return axios.post(url, body,  { responseType: 'text' });
  }
}