<template>
  <v-btn
    :disabled="disabled"
    :small="true"
    :class="[
      { 'blue white--text': primary },
      { 'red darken-1 white--text': secondary },
      { 'yellow darken-3 white--text': warning },
      { 'green white--text': success },
    ]"
  >
    <v-icon :style="estiloIcone" small> mdi-{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: [
    "primary",
    "secondary",
    "icon",
    "warning",
    "success",
    "small",
    "disabled",
    "estiloIcone",
  ],
};
</script>

<style></style>
