<template>
  <v-col cols="12" :md="cols">
    <v-select
      outlined
      v-model="selected"
      hide-details="auto"
      :key="keyProp"
      :placeholder="placeholder"
      :item-text="keyName"
      :item-value="keyValue"
      :rules="rules"
      :label="label"
      :items="items"
      :clearable="clearable"
      :readonly="readonly"
      :disabled="disabled"
      :multiple="multiple"
      :return-object='retornarObjeto'
      @click="$emit('click')"
      @change="$emit('change', $event)"
    >
      <template v-slot:no-data>
        <div class="no-data">
          <p>{{ textoNadaEncontrado || 'Nada encontrado' }}.</p>
        </div>
      </template>
    </v-select>


      <div v-if="itensSelecionados" class="container-chips">
        <v-chip-group active-class="primary--text" column>
          <v-chip v-for="tag in itensSelecionados" :key="tag">
            {{ tag.descricao }}
          </v-chip>
        </v-chip-group>
      </div>
  </v-col>
</template>

<script>
export default {
  name: 'SelectField',
  props: [
    'itensSelecionados',
    'keyProp',
    'multiple',
    'textoNadaEncontrado',
    'label',
    'placeholder',
    'cols',
    'row',
    'name',
    'items',
    'keyName',
    'keyValue',
    'rules',
    'valueField',
    'clearable',
    'disabled',
    'readonly',
    'retornarObjeto',
  ],
  data() {
    return {
      selected: this.valueField || null
    }
  },
  watch: {
    valueField: {
      immediate: true,
      handler(newText, oldText) {
        this.selected = newText
      }
    },
    selected(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit('update:value', newVal)
      }
    }
  }
}
</script>

<style scoped>
.label {
  font-weight: 600;
  color: rgb(98, 98, 98);
  margin-bottom: 5px;
}
.no-data {
  margin-top: 20px;
  margin-left: 10px;
  color: #c3c3c3;
}
</style>
