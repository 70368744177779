<template>
  <v-chip
    :color="color"
    :class="[
      { 'blue white--text': primary },
      { 'red darken-1 white--text': secondary },
      { 'yellow darken-3 white--text': warning },
      { 'green darken-2 white--text': success },
      { 'red darken-1 white--text': error },
    ]"
    dark
  >
    <slot></slot>
  </v-chip>
</template>

<script>
export default {
  props: [
    "color", "primary",
    "secondary", "warning",
    "success", "error",
  ],
};
</script>

<style></style>
