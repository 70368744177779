<template>
  <v-tooltip
  :color="color" :left="left"
  :right="right" :top="top" :bottom="bottom"
>
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <slot></slot>
      </div>
    </template>
    <span>{{ text }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: [
    "text",
    "color",
    "top",
    "bottom",
    "left",
    "right",
  ],
};
</script>