<template>

  <v-container>
    <CrudTable
      tabelaKey="id"
      :tabelaComDetalhes="true"
      :headers="headers"
      :items="auxilios"
      :pagination="paginacao"
      :loading="carregando"
      :keyPaginacao="keyPaginacao"
      @actualPage="page"
      @clickRow="detalharAuxilio($event)"
    >
      <template v-slot:top>
        <v-toolbar flat :class="$vuetify.breakpoint.xs ? 'scroll' : ''">

          <v-spacer></v-spacer>

          <ButtonMain
            @click.native="abrirFiltro()"
            text="filtrar"
            :primary="true"
          ></ButtonMain>

          <ButtonMain
            v-if="filtroAplicado"
            text="Limpar filtros"
            class="ml-2 warning"
            :primary="true"
            @click.native="
              limparFiltro()
              filtroAplicado = false
            "
          ></ButtonMain>

          <DialogConfirmation
            title="Filtros"
            :scrollable="true"
            :width="720"
            :show="dialogFiltroAberto"
            :key="keyDialogFiltro"
            :tag="'add'"
            @close="dialogFiltroAberto = false"
            @confirm="aplicarFiltro(); dialogFiltroAberto = false"
          >
            <template v-slot:body>
              <v-row class="mt-3">
                <InputField
                  label="Nome"
                  :cols="6"
                  :valueField="filtro.nome"
                  @update:value="filtro.nome = $event"
                />

                <InputField
                  label="Inscrição OAB"
                  :valueField="filtro.oab"
                  :cols="6"
                  @update:value="filtro.oab = $event"
                />

                <InputMaskField
                  label="CPF"
                  maskFormat="###.###.###-##"
                  :valueField="filtro.cpf"
                  :cols="6"
                  @update:value="filtro.cpf = $event"
                />

                <SelectField
                  label="Status"
                  keyValue="id"
                  keyName="descricao"
                  :items="listaStatus"
                  :cols="6"
                  :valueField="filtro.status"
                  @update:value="filtro.status = $event"
                />
              </v-row>
            </template>
          </DialogConfirmation>

        </v-toolbar>
      </template>

      <template v-slot:[`item.cpf`]="{ item }">
        <span>{{ item.cpf | VMask('###.###.###-##') }}</span>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <StatusChip :color="setColor(item.status)">
          {{ item.status || 'NÃO INFORMADO' }}
        </StatusChip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">

        <v-dialog width="600">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              v-bind="attrs"
              v-on="on"
              color="blue"
              :disabled="item.atualiazandoStatus"
              :loading="item.atualiazandoStatus"
              @click.native="dialogStatusAberto = true"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small color="white" v-bind="attrs" v-on="on">
                    mdi-swap-horizontal
                  </v-icon>
                </template>
                <span>Alterar Status</span>
              </v-tooltip>
            </v-btn>
          </template>

          <template v-slot:default="dialog">
            <v-card>
              <v-toolbar
                class="blue white--text"
                elevation="1"
                dense
              >
                <v-toolbar-title>Alterar Status</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  @click="dialog.value = false"
                  color="white"
                  icon
                  class="hidden-xs-only"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>

              <v-divider></v-divider>

              <v-card-text class="text-5 body-1 my-6">
                <v-row class="mt-3">
                  <SelectField
                    label="Status"
                    keyValue="id"
                    keyName="descricao"
                    :items="listaStatus"
                    :cols="12"
                    :valueField="statusSelecionado"
                    @update:value="statusSelecionado = $event"
                  />
                </v-row>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  color="red darken-1"
                  text
                  @click="
                    dialog.value = false;
                    statusSelecionado = 0
                  "
                >Cancelar</v-btn>

                <v-btn
                  color="blue darken-1"
                  text
                  @click="
                    alterarStatus(item, dialog)
                  "
                  >Confirmar</v-btn
                >
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </template>
    </CrudTable>
  </v-container>

</template>

<script>
  import CrudTable from '@/components/molecules/tables/CrudTable'
  import ButtonIcon from '@/components/atoms/buttons/ButtonIcon.vue'
  import ButtonMain from '@/components/atoms/buttons/ButtonMain.vue'
  import auxilioService from '@/services/AuxilioService'
  import utilService from '@/services/UtilService'
  import StatusChip from '@/components/atoms/StatusChip.vue'
  import DialogConfirmation from '@/components/molecules/dialogs/DialogConfirmation.vue'
  import InputMaskField from '@/components/atoms/fields/InputMaskField.vue'
  import InputField from '@/components/atoms/fields/InputField.vue'
  import SelectField from '@/components/atoms/fields/SelectField.vue'
  import TooltipMain from '@/components/atoms/TooltipMain.vue'


  export default {
    components: {
      CrudTable,
      ButtonMain,
      ButtonIcon,
      StatusChip,
      DialogConfirmation,
      InputField,
      SelectField,
      InputMaskField,
      TooltipMain
    },

    data() {
      return {
        carregando: false,
        filtroAplicado: false,
        dialogFiltroAberto: false,
        dialogStatusAberto: false,
        keyPaginacao: 0,
        keyDialogFiltro: 0,
        keyDialogStatus: 15,
        itensPorPagina: 10,

        statusSelecionado: 0,
        filtro: {
          nome: '',
          oab: '',
          cpf: '',
          status: '',
        },
        paginacao: {
          page: 0,
          size: 10,
          total: 0
        },

        auxilios: [],
        listaStatus: [],
        headers: [
          { text: 'Número', value: 'id', sortable: false },
          { text: 'Nome', value: 'nome', width: 350, sortable: false },
          { text: 'OAB', value: 'oab', align: 'center', sortable: false },
          { text: 'CPF', value: 'cpf', align: 'center', sortable: false },
          { text: 'Filho(a)', value: 'nomeFilho', align: 'center', sortable: false },
          { text: 'Data do Requerimento', align: 'center', value: 'dataCriacao', sortable: false },
          { text: 'Status', align: 'center', value: 'status', sortable: false },
          { text: 'Ações', value: 'actions', align: 'center', width: 150, sortable: false }
        ],
      }
    },

    created() {
      const paginacao = { page: this.paginacao.page, size: this.itensPorPagina };
      this.filtroAuxilios(paginacao);
      this.receberStatusAuxilio();
    },

    methods: {
      alterarStatus(auxilio, dialog){
        if(!auxilio.idStatus) {
          this.$root.vtoast.show({
            status: 'error',
            text: 'Status não encontrado',
          });

          dialog.value = false;
          return;
        }

        if(!this.statusSelecionado) {
          this.$root.vtoast.show({
            status: 'error',
            text: 'Selecione um status',
          });

          return;
        }

        auxilio.atualiazandoStatus = true;
        auxilioService.alterarStatus(
          auxilio.id,
          { id: this.statusSelecionado }
        )
          .then(() => {
            const paginacao = { page: this.paginacao.page - 1, size: 10 };
            this.filtroAuxilios(paginacao);
          })
          .catch((err) => {
            this.$root.vtoast.show({
              status: 'error',
              text: err.response?.data.mensagemUsuario,
              title: err.response?.data.titulo
            })
          })
          .then(() => {
            auxilio.atualiazandoStatus = false;
            this.statusSelecionado = 0;
            dialog.value = false;
          });
      },

      filtroAuxilios(paginacao) {
        this.carregando = true;
        const filtro = {
          ...this.filtro,
          ...paginacao,
          cpf: this.filtro?.cpf.replace(/[^0-9.]/, '') || ''
        }

        auxilioService.filtroAuxilios(filtro)
          .then(({ data }) => {
            this.auxilios = data.content.map(item => ({
              ...item,
              dataCriacao: item?.dataCriacao || '-',
              atualiazandoStatus: false,
            }));

            this.paginacao.total = data.totalPages
          })
          .catch((err) => {
            this.$root.vtoast.show({
              status: 'error',
              text: err.response?.data.mensagemUsuario,
              title: err.response?.data.titulo
            })
          })
          .then(() => this.carregando = false);
      },

      receberStatusAuxilio() {
        utilService.listaStatusAuxilio()
          .then(({ data }) => {
            this.listaStatus = data
          })
      },

      abrirFiltro() {
        this.receberStatusAuxilio();
        this.filtro = { nome: '', oab: '', cpf: '', status: '' }
        this.dialogFiltroAberto = true;
      },

      aplicarFiltro() {
        ++this.keyPaginacao;
        this.filtroAplicado = true;
        this.carregando = true;
        this.paginacao.page = 1;
        const paginacao = { page: 0, size: 10 };
        this.filtroAuxilios(paginacao);
        this.carregando = false
      },

      limparFiltro() {
        ++this.keyPaginacao;
        this.carregando = true;
        this.filtro = { nome: '', oab: '', cpf: '', status: '' }
        this.paginacao.page = 1;
        const paginacao = { page: 0, size: this.itensPorPagina };
        this.filtroAuxilios(paginacao);
        this.carregando = false;
      },


      detalharAuxilio(auxilio) {
        this.$router.push({ name: 'DetalhesAuxilioMaternidade', params: { id: auxilio.id } })
      },

      setColor(status) {
        if (status === 'DEFERIDO'|| status === 'PAGO') return 'green';
        if (status === 'INDEFERIDO') return 'red';
        return 'orange';
      },

      page(page) {
        this.carregando = true
        this.paginacao.page = page - 1;
        const paginacao = { page: this.paginacao.page, size: this.itensPorPagina };
        this.filtroAuxilios(paginacao);
        this.carregando = false;
      },
    }
  }
</script>