var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('CrudTable',{attrs:{"tabelaKey":"id","tabelaComDetalhes":true,"headers":_vm.headers,"items":_vm.auxilios,"pagination":_vm.paginacao,"loading":_vm.carregando,"keyPaginacao":_vm.keyPaginacao},on:{"actualPage":_vm.page,"clickRow":function($event){return _vm.detalharAuxilio($event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{class:_vm.$vuetify.breakpoint.xs ? 'scroll' : '',attrs:{"flat":""}},[_c('v-spacer'),_c('ButtonMain',{attrs:{"text":"filtrar","primary":true},nativeOn:{"click":function($event){return _vm.abrirFiltro()}}}),(_vm.filtroAplicado)?_c('ButtonMain',{staticClass:"ml-2 warning",attrs:{"text":"Limpar filtros","primary":true},nativeOn:{"click":function($event){_vm.limparFiltro()
            _vm.filtroAplicado = false}}}):_vm._e(),_c('DialogConfirmation',{key:_vm.keyDialogFiltro,attrs:{"title":"Filtros","scrollable":true,"width":720,"show":_vm.dialogFiltroAberto,"tag":'add'},on:{"close":function($event){_vm.dialogFiltroAberto = false},"confirm":function($event){_vm.aplicarFiltro(); _vm.dialogFiltroAberto = false}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('v-row',{staticClass:"mt-3"},[_c('InputField',{attrs:{"label":"Nome","cols":6,"valueField":_vm.filtro.nome},on:{"update:value":function($event){_vm.filtro.nome = $event}}}),_c('InputField',{attrs:{"label":"Inscrição OAB","valueField":_vm.filtro.oab,"cols":6},on:{"update:value":function($event){_vm.filtro.oab = $event}}}),_c('InputMaskField',{attrs:{"label":"CPF","maskFormat":"###.###.###-##","valueField":_vm.filtro.cpf,"cols":6},on:{"update:value":function($event){_vm.filtro.cpf = $event}}}),_c('SelectField',{attrs:{"label":"Status","keyValue":"id","keyName":"descricao","items":_vm.listaStatus,"cols":6,"valueField":_vm.filtro.status},on:{"update:value":function($event){_vm.filtro.status = $event}}})],1)]},proxy:true}])})],1)]},proxy:true},{key:"item.cpf",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("VMask")(item.cpf,'###.###.###-##')))])]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_c('StatusChip',{attrs:{"color":_vm.setColor(item.status)}},[_vm._v(" "+_vm._s(item.status || 'NÃO INFORMADO')+" ")])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-dialog',{attrs:{"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","color":"blue","disabled":item.atualiazandoStatus,"loading":item.atualiazandoStatus},nativeOn:{"click":function($event){_vm.dialogStatusAberto = true}}},'v-btn',attrs,false),on),[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"white"}},'v-icon',attrs,false),on),[_vm._v(" mdi-swap-horizontal ")])]}}],null,true)},[_c('span',[_vm._v("Alterar Status")])])],1)]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-toolbar',{staticClass:"blue white--text",attrs:{"elevation":"1","dense":""}},[_c('v-toolbar-title',[_vm._v("Alterar Status")]),_c('v-spacer'),_c('v-btn',{staticClass:"hidden-xs-only",attrs:{"color":"white","icon":""},on:{"click":function($event){dialog.value = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"text-5 body-1 my-6"},[_c('v-row',{staticClass:"mt-3"},[_c('SelectField',{attrs:{"label":"Status","keyValue":"id","keyName":"descricao","items":_vm.listaStatus,"cols":12,"valueField":_vm.statusSelecionado},on:{"update:value":function($event){_vm.statusSelecionado = $event}}})],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){dialog.value = false;
                  _vm.statusSelecionado = 0}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.alterarStatus(item, dialog)}}},[_vm._v("Confirmar")])],1)],1)]}}],null,true)})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }